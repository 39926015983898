import { Controller } from "@hotwired/stimulus";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { createRoot } from "react-dom/client";
import { CheckoutContainer } from "src/checkout/checkout";


export default class extends Controller<HTMLFormElement> {
  static targets = ["checkout"];
  declare checkoutTarget : HTMLDivElement;

  static values = {
    paymentAmountInCents: Number,
    stripePublicKey: String,
    afterpayAllowed: Boolean,
    billingEmail: String,
    billingName: String,
    allowSaveCard: Boolean,
    confirmPath: String,
  };

  declare paymentAmountInCentsValue?: number;
  declare stripePublicKeyValue?: string;
  declare afterpayAllowedValue: boolean;
  declare billingEmailValue: string;
  declare billingNameValue: string;
  declare allowSaveCardValue: boolean;
  declare confirmPathValue: string;

  async connect() {
    if (!this.stripePublicKeyValue || this.paymentAmountInCentsValue === undefined || !this.confirmPathValue) {
      throw "Missing checkout configuration";
    }

    // Older version of the customer app that can't handle Stripe redirects in-app, which means it would
    // try and open it in an external browser, and the payment flow won't work; disable Afterpay.
    const afterpaySupported = !!window.ConfigureInAppDomains || !navigator.userAgent.includes("DelivereasyCustomer");

    const stripe = await loadStripe(this.stripePublicKeyValue);
    const root = createRoot(this.checkoutTarget);
    root.render(React.createElement(CheckoutContainer, {
      paymentAmountInCents: this.paymentAmountInCentsValue,
      stripe: stripe,
      formElement: this.element,
      afterpayEnabled: afterpaySupported && this.afterpayAllowedValue,
      billingName: this.billingNameValue,
      billingEmail: this.billingEmailValue,
      allowSaveCard: this.allowSaveCardValue,
      confirmPath: this.confirmPathValue,
    }));
  }

  async submit(event: Event): Promise<void> {
    event.preventDefault();
  }
}
