import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  displayConfirm(event: Event) {
    var response = confirm("Are you sure you want to cancel your account?");
    if (response == true) {
      return;
    } else {
      event.preventDefault();
    }
  }
}
