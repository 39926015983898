import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton"];

  declare submitButtonTarget : HTMLButtonElement;

  disable() : void {
    this.submitButtonTarget.disabled = true;
    const form = this.submitButtonTarget.form
    if (form === null) return;

    form.submit();
  }
}
