import React, { useState, useEffect, useRef } from "react";

import { classNames } from "src/common_util";
import { ReactComponent as PasswordType } from "images/icons/password-type.svg";
import { ReactComponent as LoadingSpinner } from "images/animated/loading_spinner.svg";

type CredentialRequestOptions = {
  otp: { transport: string[] };
  signal: AbortSignal;
}

const VerifyPhoneNumberModal = ({
  phoneNumber,
  isPending,
  error,
  clearError,
  cancel,
  submit,
} : {
  phoneNumber: string;
  isPending: boolean;
  error: string | undefined;
  clearError: () => void;
  cancel: () => void;
  submit: () => void;
}) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const codeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if ('OTPCredential' in window && !isPending) {
      const abortController = new AbortController();

      navigator.credentials.get({
        otp: { transport: ["sms"] },
        signal: abortController.signal,
      } as CredentialRequestOptions).then((credential) => {
        if (credential && 'code' in credential && codeInputRef.current && /^[0-9]{6}$/.test(credential.code as string)) {
          setIsValid(true);
          codeInputRef.current.value = (credential.code as string);
          submit();
        }
      }).catch((err) => {
        if (err instanceof DOMException && (err.name == 'AbortError' || err.name == 'SecurityError' || err.name == 'InvalidStateError')) return;
        throw err;
      });

      return () => { abortController.abort(); }
    }
  }, [codeInputRef, isPending, submit]);

  const onInput = () => {
    clearError();
    if (codeInputRef.current && /^[0-9]{6}$/.test(codeInputRef.current.value)) {
      setIsValid(true);
      submit();
    } else {
      setIsValid(false);
    }
  }

  const successButtonClasses = classNames({
    "button": true,
    "is-primary": true,
    "is-full-width": true,
    "is-loading": isPending,
  });

  return (
    <div className="popover-sheet is-active is-modal is-floating-above-desktop">
      <div className="shim" onClick={(event) => event.currentTarget == event.target && cancel()}></div>

      <div className="surface">
        <div className="content">
          <section className="container width-constraint-extra-narrow">
            <div className="is-center-aligned-text">
              <span className="icon custom-icon is-hero">
                <PasswordType />
              </span>

              <h4 className="space-mt-none space-mb-lg">Can I get your digits?</h4>
            </div>

            <p className="space-mt-sm">We just need to check we have the right phone number in case we need to contact you about your order.</p>
            <p className="space-mt-sm">Please enter the verification code we just sent to your mobile ({phoneNumber}):</p>

            <div className="space-mt-lg space-pb-sm is-full-width text-input has-hidden-label space-mb-none has-monospaced-numbers">
              <label htmlFor="verification_code">Verification code</label>
              <input
                name="verification_code" type="text" maxLength={6} inputMode="numeric" autoComplete="one-time-code" required pattern="\d{6}" autoFocus={true} ref={codeInputRef} style={{ fontSize: '2rem', letterSpacing: '1rem', lineHeight: 1.75, padding: '0 1rem' }}
                onKeyPress={(event) => /[0-9]/.test(event.key) || event.preventDefault()}
                onInput={onInput}
              />
            </div>

            {error ? <div className="is-center-aligned-text is-error-text">{error}</div> : <></>}

            <div className="is-flexbox is-flex-gap-sm space-pt-sm">
              <button className="button is-full-width" aria-label="Cancel" onClick={cancel}>Cancel</button>
              <button className={successButtonClasses} aria-label="Verify" disabled={isPending || !isValid} data-cy="submit-verification-code" onClick={submit}>
                {isPending ? <>
                  <span className="icon custom-icon">
                    <LoadingSpinner />
                  </span></> : <></>}
                <span>Verify</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export { VerifyPhoneNumberModal };
