import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "stackable", "aside", "asidePadder", "asideCheck", "asidePaddingSource"
  ];

  declare stackableTargets: HTMLElement[];

  declare asideTarget: HTMLElement;
  declare asidePadderTargets: HTMLElement[];
  declare asideCheckTarget: HTMLElement;
  declare hasAsideCheckTarget: boolean;
  declare asidePaddingSourceTarget: HTMLElement;

  connect() {
    this.reset();
  }

  reset() {
    this.stackableTargets.reduce((running, stackable) => {
      if (stackable.style.visibility !== "hidden") {
        stackable.setAttribute("style", `top: ${running}px;`);
        return (running + stackable.offsetHeight);
      } else {
        return running;
      }
    }, 0);

    this.setCartMaxHeight();
  }

  setCartMaxHeight() {
    if (this.hasAsideCheckTarget === true) {
      const checkStyle = window.getComputedStyle(this.asideCheckTarget);
      if (checkStyle.display === "none") {

        const padders = this.asidePadderTargets.reduce((sum, el) => sum + el.offsetHeight, 0);
        let bottom = parseInt(window.getComputedStyle(this.asidePaddingSourceTarget).paddingTop);
        let cartHeightOffsetDesktop = padders + bottom * 2;

        this.asideTarget.setAttribute("style", `--cart-height-offset-desktop: ${cartHeightOffsetDesktop}`);
      }
    }
  }
}
