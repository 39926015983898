import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "location", "choiceStrip", "controls"
  ];

  declare locationTarget: HTMLElement;
  declare choiceStripTarget: HTMLElement;
  declare controlsTarget: HTMLElement;

  connect() {
    this.setControlVisibility();
  }

  setControlVisibility() {
    const choiceStripWidth = this.choiceStripTarget.clientWidth;
    const choiceStripScrollWidth = this.choiceStripTarget.scrollWidth;

    if (choiceStripScrollWidth > choiceStripWidth && this.controlsTarget.classList.contains("is-completely-hidden")) {
      this.controlsTarget.classList.remove("is-completely-hidden");
      this.choiceStripTarget.scroll({ behavior: "auto", left: 0 });
    } else if (choiceStripScrollWidth <= choiceStripWidth && ~this.controlsTarget.classList.contains("is-completely-hidden")) {
      this.controlsTarget.classList.add("is-completely-hidden");
    }
  }

  previous(event: Event) {
    event.preventDefault();

    const viewportWidth = document.body.clientWidth;
    const scrollPosition = this.choiceStripTarget.scrollLeft;

    this.choiceStripTarget.scroll({ left: scrollPosition - viewportWidth });
  }

  next(event: Event) {
    event.preventDefault();

    const viewportWidth = document.body.clientWidth;
    const scrollPosition = this.choiceStripTarget.scrollLeft;

    this.choiceStripTarget.scroll({ left: scrollPosition + viewportWidth });
  }

  handleResize() {
    this.setControlVisibility();
  }
}
