import { OrderInfo } from "src/order_info";

import MapPath from "src/map_path";

import restaurantMarkerIcon from "../images/map/restaurant-marker-icon.svg";
import customerMarkerIcon from "../images/map/customer-marker-icon.svg";
import driverMarkerIcon from "../images/map/driver-marker-icon.svg";
import syncLocationIcon from "../images/icons/sync-location.svg";

export default class OrderTrackerMap {
  orderInfo: OrderInfo;

  map: google.maps.Map;
  customerMarker: google.maps.Marker;
  restaurantMarker: google.maps.Marker;

  driverMarker: google.maps.Marker;
  currentPosition?: google.maps.LatLng;
  currentPositionReceivedAt: DOMHighResTimeStamp = window.performance.now();
  currentRoute?: MapPath;
  currentRoutePolyline: google.maps.Polyline;

  lastPosition?: google.maps.LatLng;
  lastInterval = 1000;

  gradientOverlay: HTMLElement;
  topLeftControl: HTMLElement;

  constructor(mapTarget: HTMLElement, orderInfo: OrderInfo) {
    this.orderInfo = orderInfo;

    this.map = new google.maps.Map(mapTarget, {
      center: { lat: 0, lng: 0 },
      zoom: 1,
      maxZoom: 17,
      gestureHandling: "greedy",
      keyboardShortcuts: false,
      styles: [
        // Simple grey theme
        {
          "featureType": "landscape",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels",
          "stylers": [{ "visibility": "on" }]
        },
        {
          "featureType": "water",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "visibility": "on"
            },
            {
              "lightness": 24
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "lightness": 57
            }
          ]
        }
      ],
      mapTypeControl: false,
      streetViewControl: false,
    });

    this.gradientOverlay = document.createElement("div");
    this.topLeftControl = document.createElement("div");

    this.gradientOverlay.classList.add("custom-map-ui", "is-map-overlay", "is-gradient-top", "is-completely-hidden");
    this.topLeftControl.classList.add("custom-map-ui", "is-ui-control", "is-top-left", "is-completely-hidden");

    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.gradientOverlay);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.topLeftControl);

    this.customerMarker = new google.maps.Marker({
      map: this.orderInfo.deliveryLocation != null ? this.map : undefined,
      position: this.orderInfo.deliveryLocation,
      title: this.orderInfo.deliveryAddressLabel,
      zIndex: 2,
      icon: {
        url: customerMarkerIcon,
        scaledSize: new google.maps.Size(48, 48),
        anchor: new google.maps.Point(24, 24),
      },
    });

    this.restaurantMarker = new google.maps.Marker({
      map: this.map,
      position: this.orderInfo.restaurantLocation,
      title: this.orderInfo.restaurantName,
      zIndex: 1,
      icon: {
        url: restaurantMarkerIcon,
        scaledSize: new google.maps.Size(48, 48),
        anchor: new google.maps.Point(24, 24),
      }
    });

    // create the driver marker and route polyline but don't put them on the map yet -
    // we need to wait for data from pubnub to know where they go
    this.driverMarker = new google.maps.Marker({
      zIndex: 3,
      icon: {
        url: driverMarkerIcon,
        scaledSize: new google.maps.Size(48, 48),
        anchor: new google.maps.Point(24, 24),
      },
    });
    this.currentRoutePolyline = new google.maps.Polyline({
      strokeColor: "rgb(255, 20, 20)",
      strokeOpacity: 1.0,
      strokeWeight: 5,
      clickable: false,
    });
  }

  showRouteAndDriverMarker(): void {
    this.currentRoutePolyline?.setMap(this.map ?? null);
    this.driverMarker?.setMap(this.map ?? null);
  }

  hideRouteAndDriverMarker(): void {
    this.currentRoutePolyline?.setMap(null);
    this.driverMarker?.setMap(null);
  }

  hideRestaurantMarker(): void {
    // hide the restaurant marker, unless we don't have a customer marker, in which case
    // the driver will quickly move off the map and we'd get left with an empty map
    if (this.customerMarker.getPosition()) {
      this.restaurantMarker?.setMap(null);
    }
  }

  showRestaurantMarker(): void {
    this.restaurantMarker?.setMap(this.map ?? null);
  }

  setRoute(polylines: string[]): void {
    this.currentRoute = MapPath.decode(polylines);
    this.updateRoutePolyline();
  }

  private updateRoutePolyline(): void {
    if (this.currentRoute && this.currentPosition) {
      const trimmedRoute = this.currentRoute.trim(this.currentPosition);
      this.currentRoutePolyline?.setPath(trimmedRoute);
    }
  }

  private animateDriverMarker(now: DOMHighResTimeStamp): void {
    // Animate the marker towards the currentPosition over the interval it just actually moved over
    // (so we're always one interval behind). Note that we don't call updateRoutePolyline again to
    // re-calculate the clipped path, so the route line immediately moves to start from currentPosition
    // rather than animating; that method is actually fast enough that we could do that too if we wanted
    // to, but it seems OTT (and you notice the marker not being on the end of the line, so we'd
    // probably want to switch to forcing the marker onto the path, too).
    const fraction = (now - this.currentPositionReceivedAt)/this.lastInterval;
    if (fraction < 1 && this.lastPosition != null && this.currentPosition != null) {
      const position = new google.maps.LatLng({
        lat: this.lastPosition.lat() + (this.currentPosition.lat() - this.lastPosition.lat())*fraction,
        lng: this.lastPosition.lng() + (this.currentPosition.lng() - this.lastPosition.lng())*fraction,
      });
      this.driverMarker?.setPosition(position);
      window.requestAnimationFrame(this.animateDriverMarker.bind(this));
    } else {
      this.driverMarker?.setPosition(this.currentPosition ?? null);
    }
  }

  updatePosition(position: google.maps.LatLngLiteral): void {
    const now = window.performance.now();

    // Generally we animate the marker position starting from wherever it is now, even if it hasn't caught
    // up to the old currentPosition. This has the advantage of not jumping forward when we get points in
    // quick succession after a longer pause in location signals. But requestAnimationFrame doesn't call
    // back when the map is off-screen, so in that case the marker position doesn't move forward at all,
    // and when the user does eventually switch/scroll the map back into view, they'd get a silly-looking
    // straight-line marker animation between the last position they actually saw and the current position.
    // So if we can see the marker was not moved at all past the old lastPosition, jump it forward first.
    // This means that we will, at worst, straight line 1.99 intervals' movement, which is not bad.
    const markerPosition = this.driverMarker?.getPosition();
    this.lastPosition = !markerPosition || !this.lastPosition || markerPosition.equals(this.lastPosition) ? this.currentPosition : markerPosition;
    this.lastInterval = Math.max(Math.min(now - this.currentPositionReceivedAt, 15000), 1000);
    this.currentPosition = new google.maps.LatLng(position);
    this.currentPositionReceivedAt = now;

    if (this.driverMarker?.getMap()) {
      // marker already showing on the map, animate its movement; actually making it visible is done by
      // showMapElementsForStatus shortly
      this.animateDriverMarker(now);
    } else {
      // first coordinate received, place the marker directly in the current position
      this.driverMarker?.setPosition(this.currentPosition);
    }

    this.updateRoutePolyline();
  }

  clearPosition(): void {
    this.currentPosition = undefined;
  }

  setOutForDeliveryBounds(): void {
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(this.orderInfo.deliveryLocation);
    bounds.extend(this.orderInfo.restaurantLocation);
    this.map?.fitBounds(bounds);
  }

  setArrivingBounds(): void {
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(this.orderInfo.deliveryLocation);
    bounds.extend(this.currentPosition ?? this.orderInfo.restaurantLocation);
    this.map?.fitBounds(bounds);
  }

  setCenter(latlng: google.maps.LatLngLiteral, zoom = 17): void {
    this.map?.setCenter(latlng);
    this.map?.setZoom(zoom);
  }

  private showOnlyTheseCustomMapElements(elements: HTMLElement[]): void {
    // Hide all custom map UI elements
    document.querySelectorAll(".custom-map-ui").forEach(element => {
      element.classList.add("is-completely-hidden");
    });

    // Show any specified UI elements
    elements.forEach(element => {
      element.classList.remove("is-completely-hidden");
    });
  }

  showOverlayMessage(message: string): void {
    if (this.topLeftControl && this.gradientOverlay) {
      this.topLeftControl.innerHTML = message;
      this.showOnlyTheseCustomMapElements([this.topLeftControl, this.gradientOverlay]);
    }
  }

  hideOverlayMessage(): void {
    this.showOnlyTheseCustomMapElements([]);
  }

  showUpNextMessage(): void {
    this.showOverlayMessage("Your driver is working on another order. Location available soon.");
  }

  showNoDataMessage(): void {
    const icon = `<img class="icon streamline-icon" src="${syncLocationIcon}">`;
    this.showOverlayMessage(`${icon} <span>Waiting for driver location…</span>`);
  }
}
