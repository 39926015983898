import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["requiredField", "regexField"];

  declare requiredFieldTargets : HTMLInputElement[];
  declare regexFieldTargets : HTMLInputElement[];

  validateFields(event: Event) {
    this.clearFieldErrors();

    for (let field of this.requiredFieldTargets) {
      const visible = field.offsetWidth > 0 || field.offsetHeight;

      if (visible === true) {
        const validator = field.type;
        if (validator == "checkbox") {
          if (this.validateChecked(field, event) == false)
            break;
        } else if (validator == "select" || validator == "select-one" ) {
          if (this.validateSelected(field, event) == false)
            break;
        } else if (validator == "text") {
          if (this.validateText(field, event) == false)
            break;
        } else if (validator == "tel") {
          if (this.validateText(field, event) == false)
            break;
        }
      }
    }

    for (const field of this.regexFieldTargets) {
      const visible = field.offsetWidth > 0 || field.offsetHeight;
      if (visible === true) {
        this.validateRegex(field, event)
      }
    }
  }

  validateChecked(field: HTMLInputElement, event: Event) {
    if (field.checked != true) {
      this.abort(event);
      field.scrollIntoView({ behavior: "smooth", block: "center" } );
      this.displayFieldErrors(field);
      return false;
    }
    return true;
  }

  validateSelected(field: HTMLInputElement, event: Event) {
    if (field.value == "") {
      this.abort(event);
      field.scrollIntoView({ behavior: "smooth", block: "center" } );
      this.displayFieldErrors(field);
      return false;
    }
    return true;
  }

  validateText(field: HTMLInputElement, event: Event) {
    if (field.value == "") {
      this.abort(event);
      field.scrollIntoView({ behavior: "smooth", block: "center" } );
      this.displayFieldErrors(field);
      return false;
    }
    return true;
  }

  validateTel(field: HTMLInputElement, event: Event) {
    if (field.value == "") {
      this.abort(event);
      field.scrollIntoView({ behavior: "smooth", block: "center" } );
      this.displayFieldErrors(field);
      return false;
    }
    return true;
  }

  validateRegex(field: HTMLInputElement, event: Event) {
    if (field.value == "")
      return true

    const validRegex = field.dataset.validregex
    if (validRegex != undefined){
      const regexp = RegExp(validRegex);

      if (regexp.test(field.value)){
        return true
      } else {
        this.displayFieldErrorsWithMessage(field, event);
      }
    } else {
      this.displayFieldErrorsWithMessage(field, event);
    }
  }

  abort(event: Event) {
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  displayFieldErrors(field: HTMLInputElement) {
    const containerId = field.getAttribute("data-container") || "";
    const containerElement = document.getElementById(containerId);
    if (containerElement != null) containerElement.classList.add("has-error");
  }

  displayFieldErrorsWithMessage(field: HTMLInputElement, event: Event) : boolean {
    this.abort(event);
    field.scrollIntoView({ behavior: "smooth", block: "center" } );
    const parent = field.parentElement;
    if (parent)
      parent.classList.add("has-error");
    return false;
  }

  clearFieldErrors(){
    const elements = Array.from(document.getElementsByClassName("has-error"));

    elements.forEach(element => {
      element.classList.remove("has-error");
    });
  }
}
