import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "loadingAnimation"];

  declare modalTargets : HTMLElement[];
  declare loadingAnimationTarget : HTMLElement;

  close() {
    history.replaceState({}, '', window.location.href.replace("/confirm", ""));

    this.modalTargets.forEach((modal) => {
      modal.classList.remove("is-active");
    });
  }
}
