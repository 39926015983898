import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "header", "taglineElement", "miniTaglineElement"
  ];

  declare headerTarget: HTMLElement;
  declare taglineElementTarget: HTMLElement;
  declare miniTaglineElementTarget: HTMLElement;
  declare hasTaglineElementTarget: boolean;

  handleScroll(): void {
    if (this.hasTaglineElementTarget) {
      const bottomEdgePosition = this.taglineElementTarget.getBoundingClientRect().top + this.taglineElementTarget.offsetHeight - this.headerTarget.offsetHeight;

      if (bottomEdgePosition <= 0) {
        this.miniTaglineElementTarget.classList.remove('is-completely-hidden');
      } else {
        this.miniTaglineElementTarget.classList.add('is-completely-hidden');
      }
    }
  }
}
