import React from "react";
import { classNames } from "src/common_util";

const PayButton = ({
  paymentType,
  enabled,
  shouldTakePayment,
  handlePaymentSubmit,
} : {
  paymentType: string | undefined;
  enabled: boolean;
  shouldTakePayment: boolean;
  handlePaymentSubmit: () => Promise<void>;
}) => {
  const buttonCopy = () => {
    if (!shouldTakePayment)
      return "Confirm order";

    switch (paymentType) {
      case "afterpay_clearpay":
        return "Pay with Afterpay";

      default:
        return "Pay now"
    }
  }

  const classes = classNames({
    "button": true,
    "is-primary": true,
    "is-visually-disabled": !enabled,
  });

  return (
    <button
      name="button"
      type="submit"
      className={classes}
      disabled={!enabled}
      data-testid="pay_button"
      data-cy="place-order-button"
      onClick={handlePaymentSubmit}
    >
      <span className="icon custom-icon is-completely-hidden">
      </span>

      <span>
        {buttonCopy()}
      </span>
    </button>
  );
}

export { PayButton };
