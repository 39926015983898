import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "offsetSource", "offsetSpacer", "category", "header"
  ];

  declare offsetSourceTarget: HTMLElement;
  declare offsetSpacerTarget: HTMLElement;
  declare hasOffsetSpacerTarget: boolean;
  declare categoryTargets: HTMLElement[];
  declare headerTarget: HTMLElement;

  sectionVisibilities: { [id: string] : number } = {};
  firstMenuCategory?: Element;
  initialLeft = 0;
  scrollTimeoutID?: number;

  connect() {
    if (this.hasOffsetSpacerTarget !== true) { return; }

    this.firstMenuCategory = this.offsetSpacerTarget.children[0];
    if (this.firstMenuCategory !== undefined) {
      this.initialLeft = this.firstMenuCategory.getBoundingClientRect().left;
    }

    window.addEventListener('scroll', this.handleChoiceStripScroll)
    this.setupObservers();
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleChoiceStripScroll);
  }

  reset() {
    this.setMenuCategoryMargin();
  }

  handleChoiceStripScroll = () => {
    window.clearTimeout(this.scrollTimeoutID);

    const checkedInput = this.offsetSpacerTarget.querySelector('input:checked') as HTMLInputElement;

    if (checkedInput === null) { return; }

    const chip = checkedInput.parentElement as HTMLElement;
    const backButtonRect = this.offsetSourceTarget.getBoundingClientRect();
    const choiceStripRect = this.offsetSpacerTarget.getBoundingClientRect();
    const choiceStripGap = parseInt(window.getComputedStyle(this.offsetSpacerTarget).rowGap);
    const choiceStripPaddingLeft = parseInt(window.getComputedStyle(this.offsetSpacerTarget).paddingLeft);

    if (choiceStripRect.top - (backButtonRect.height) <= 0) {
      const chipLocation = chip.offsetLeft - (backButtonRect.right + choiceStripGap);
      this.scrollChoiceStrip(chipLocation);
    } else {
      const chipLocation = chip.offsetLeft - (backButtonRect.right + choiceStripPaddingLeft);
      this.scrollChoiceStrip(chipLocation);
    }
  }

  scrollChoiceStrip(location: number) {
    this.scrollTimeoutID = window.setTimeout(() => {
      this.offsetSpacerTarget.scroll({
        left: location,
        behavior: 'smooth',
      });
    }, 80);
  }

  scrollTo(event: Event) {
    if (event.target === null) { return; }

    const el = (event.target as HTMLInputElement);
    if (el.checked !== true) { return; }

    // Prevent chip from being highlighted / checked, checkBestHighlight() handles instead
    event.preventDefault();

    const target = document.getElementById(`${el.id}_section`);
    if (target === null) { return; }

    const targetTop = target.getBoundingClientRect().top
    const rect = this.offsetSpacerTarget.getBoundingClientRect();
    const headerRect = this.headerTarget.getBoundingClientRect();

    window.scroll({
      top: window.scrollY + targetTop - rect.height - headerRect.height,
      behavior: 'smooth',
    });
  }

  buildThresholdList() {
    let thresholds = [];
    let numSteps = 20;

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i/numSteps;
      thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
  }

  setMenuCategoryMargin() {
    if (this.firstMenuCategory === undefined) { return; }

    const spaceRect = this.offsetSpacerTarget.getBoundingClientRect();
    const sourceRect = this.offsetSourceTarget.getBoundingClientRect();

    if (spaceRect.top - (sourceRect.height) <= 0) {
      const sourceRect = this.offsetSourceTarget.getBoundingClientRect();
      const sourceGap = parseInt(window.getComputedStyle(this.offsetSpacerTarget).rowGap);

      const newMargin = sourceRect.right + sourceGap;
      if (newMargin < 0) { return; }

      this.offsetSpacerTarget.style.setProperty("padding-left", `${newMargin}px`);
    } else {
      this.offsetSpacerTarget.style.removeProperty("padding-left");
    }
  }

  setupObservers() {
    const thresholds = this.buildThresholdList();

    const observer = new IntersectionObserver((intersections) => {
      for (const entry of intersections) {
        const section = entry.target.getAttribute("data-section-name");
        if (section === null) { continue; }

        this.sectionVisibilities[section] = entry.intersectionRatio;
      }

      this.checkBestHighlight();
    }, { root: null, rootMargin: "0px", threshold: thresholds });

    for (const cat of this.categoryTargets) {
      observer.observe(cat);
    }
  }

  checkBestHighlight() {
    let bestEl : string = "";

    for (const el in this.sectionVisibilities) {
      const domEl = document.querySelector(el);
      if (domEl !== null) { (domEl as HTMLInputElement).checked = false; }

      if (bestEl == "" || this.sectionVisibilities[el] > this.sectionVisibilities[bestEl]) {
        bestEl = el;
      }
    }

    const bestDomEl = document.querySelector(`[data-section='${bestEl}']`);
    if (bestDomEl !== null) { (bestDomEl as HTMLInputElement).checked = true; }
  }
}
