import React from "react";

const TermsAndConditions = ({
  checked,
  setTermsAndConditionsChecked,
} : {
  checked: boolean;
  setTermsAndConditionsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <fieldset className="checkbox space-mb-lg" id="terms-and-conditions-container">
    <div className="error">Please select the terms and conditions</div>

    <input
      type="checkbox"
      name="terms_and_conditions"
      id="terms-and-conditions-zero"
      value="true"
      checked={checked}
      className="checkbox"
      onChange={(event) => {
        setTermsAndConditionsChecked(event.target.checked)
      }}
    />

    <label data-cy="terms-and-conditions" htmlFor="terms-and-conditions-zero">
      I have read and agree to Delivereasy’s <a className="has-underline is-normal-underline is-visible-above-tablet" href="/legal">terms and conditions</a>
    </label>

    <div className="space-pl-md space-pt-xs is-hidden-above-tablet">
      <a className="has-underline is-muted-text" href="/legal">Terms and conditions</a>
    </div>

  </fieldset>
);

export { TermsAndConditions };
