import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["couponForm", "applyCouponButton", "submitButtonAnimation", "submitButtonText"];

  declare couponFormTarget : HTMLFormElement;
  declare applyCouponButtonTarget : HTMLButtonElement;
  declare submitButtonAnimationTarget : HTMLSpanElement;
  declare submitButtonTextTarget : HTMLSpanElement;

  apply() {
    this.applyCouponButtonTarget.classList.toggle("is-loading");
    this.applyCouponButtonTarget.classList.toggle("is-icon-only");
    this.submitButtonTextTarget.classList.toggle("is-completely-hidden");
    this.submitButtonAnimationTarget.classList.toggle("is-completely-hidden");
    this.applyCouponButtonTarget.disabled = true
    this.couponFormTarget.submit();
  }
}
