import { Temporal } from "@js-temporal/polyfill";
import { JsonDecoder } from "ts.data.json";
import * as Sentry from "@sentry/browser";

export interface ISubscription {
  status: SubscriptionStatus;
  defaultPaymentMethod?: string;
  currentPeriodEndAt: Temporal.ZonedDateTime;
  cancellationRequestedAt?: Temporal.ZonedDateTime;
  subscriptionEndedAt?: Temporal.ZonedDateTime;
  nextFailedPaymentRetry?: Temporal.ZonedDateTime;
  eligibleForSubscriptionBenefits: boolean;
  currentPeriodDaysRemaining: number;
}

export class Subscription implements ISubscription {
  readonly status: SubscriptionStatus;
  readonly defaultPaymentMethod?: string;
  readonly currentPeriodEndAt: Temporal.ZonedDateTime;
  readonly cancellationRequestedAt?: Temporal.ZonedDateTime;
  readonly subscriptionEndedAt?: Temporal.ZonedDateTime;
  readonly nextFailedPaymentRetry?: Temporal.ZonedDateTime;
  readonly eligibleForSubscriptionBenefits: boolean;
  readonly currentPeriodDaysRemaining: number;

  constructor({
    status,
    defaultPaymentMethod,
    currentPeriodEndAt,
    cancellationRequestedAt,
    subscriptionEndedAt,
    nextFailedPaymentRetry,
    eligibleForSubscriptionBenefits,
    currentPeriodDaysRemaining,
  } : {
    status: SubscriptionStatus;
    defaultPaymentMethod?: string;
    currentPeriodEndAt: Temporal.ZonedDateTime;
    cancellationRequestedAt?: Temporal.ZonedDateTime;
    subscriptionEndedAt?: Temporal.ZonedDateTime;
    nextFailedPaymentRetry?: Temporal.ZonedDateTime;
    eligibleForSubscriptionBenefits: boolean;
    currentPeriodDaysRemaining: number;
  }) {
    this.status = status
    this.defaultPaymentMethod = defaultPaymentMethod;
    this.currentPeriodEndAt = currentPeriodEndAt;
    this.cancellationRequestedAt = cancellationRequestedAt;
    this.subscriptionEndedAt = subscriptionEndedAt;
    this.nextFailedPaymentRetry = nextFailedPaymentRetry;
    this.eligibleForSubscriptionBenefits = eligibleForSubscriptionBenefits;
    this.currentPeriodDaysRemaining = currentPeriodDaysRemaining;
  }

  hasBeenCancelled() {
    return this.cancellationRequestedAt != undefined;
  }

  isInTrial() {
    return this.status == "trialing";
  }

  isOverdue() {
    return this.status == SubscriptionStatus.pastDue;
  }
}

export enum SubscriptionStatus {
  active = "active",
  trialing = "trialing",
  cancelled = "cancelled",
  pastDue = "past_due",
}

const subscriptionDecoder = JsonDecoder.object<ISubscription>({
  "status": JsonDecoder.enumeration<SubscriptionStatus>(SubscriptionStatus, 'SubscriptionStatus'),
  "defaultPaymentMethod": JsonDecoder.optional(JsonDecoder.string),
  "currentPeriodEndAt": JsonDecoder.string.map(stringDate => parseIso8601(stringDate)),
  "cancellationRequestedAt": JsonDecoder.optional(JsonDecoder.string.map(stringDate => parseIso8601(stringDate))),
  "subscriptionEndedAt": JsonDecoder.optional(JsonDecoder.string.map(stringDate => parseIso8601(stringDate))),
  "nextFailedPaymentRetry": JsonDecoder.optional(JsonDecoder.string.map(stringDate => parseIso8601(stringDate))),
  "eligibleForSubscriptionBenefits": JsonDecoder.boolean,
  "currentPeriodDaysRemaining": JsonDecoder.number,
}, "Subscription");

const assembleSubscriptions = (subscription: ISubscription) => {
  return new Subscription({
    status: subscription.status,
    defaultPaymentMethod: subscription.defaultPaymentMethod,
    currentPeriodEndAt: subscription.currentPeriodEndAt,
    cancellationRequestedAt: subscription.cancellationRequestedAt,
    subscriptionEndedAt: subscription.subscriptionEndedAt,
    nextFailedPaymentRetry: subscription.nextFailedPaymentRetry,
    eligibleForSubscriptionBenefits: subscription.eligibleForSubscriptionBenefits,
    currentPeriodDaysRemaining: subscription.currentPeriodDaysRemaining,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseSubscriptions = (subscriptionJson: any) : Subscription => {
  return subscriptionDecoder.fold(tree => {
    return assembleSubscriptions(tree);
  }, err => {
    Sentry.captureMessage(`Unable to process active subscription: ${err}`);

    throw `Unable to process active subscription`;
  }, subscriptionJson);
}

const parseIso8601 = (isoDateTime: string) => {
  return Temporal.Instant.from(isoDateTime).toZonedDateTimeISO('Pacific/Auckland');
}
