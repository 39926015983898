import { SearchClient } from "algoliasearch/lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { AutocompleteState, BaseItem, createAutocomplete } from "@algolia/autocomplete-core";

import AutocompleteConfig from "src/components/autocomplete/autocomplete_config";

import debounce from "src/debounce";
import AutocompleteRenderer from "./autocomplete_renderer";

export default function Autocomplete<T extends BaseItem>({ searchClient, autocompleteConfig, inputElements, clearInputElements, popoverContainer, initialQuery }: { searchClient: SearchClient; autocompleteConfig: AutocompleteConfig<T>; inputElements: HTMLInputElement[]; clearInputElements: HTMLElement[]; popoverContainer: HTMLElement; initialQuery: string }) {
  const [autocompleteState, setAutocompleteState] = React.useState<AutocompleteState<T>>({
    activeItemId: null,
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: initialQuery,
    status: "idle",
  });

  const autocomplete = React.useMemo(
    () =>
      createAutocomplete<T>({
        onStateChange({ state }) {
          setAutocompleteState(state);
        },
        initialState: { query: initialQuery },
        openOnFocus: true,
        insights: true,
        getSources({ query }) {
          if (!query) {
            return [autocompleteConfig.emptyQueryResults()];
          }

          return [autocompleteConfig.queryResults(searchClient)]
        },
      }),
    [searchClient, autocompleteConfig, initialQuery]
  );

  const updateQuerySearchParams = useCallback((query?: string) => {
    const url = new URL(window.location.toString());

    if (query) {
      url.searchParams.set("search_bar", query);
    } else {
      url.searchParams.delete("search_bar");
    }

    history.replaceState(null, '', url);
  }, []);

  const updateQuery = React.useCallback((value: string) => {
    updateQuerySearchParams(value);

    autocomplete.setQuery(value);
    autocomplete.refresh();
  }, [autocomplete, updateQuerySearchParams]);

  const resultsRef = React.useRef<HTMLInputElement>(null)

  const handleKeyboardNavigation = useCallback((e: KeyboardEvent) => {
    const inputIsFocused = document.activeElement

    if (resultsRef?.current == undefined) { return }

    const resultItems:HTMLCollection = resultsRef?.current?.children

    const resultItemsToArray = Array.from(resultItems)
    // an array to use to loop through
    const activeResultIndex = resultItemsToArray.findIndex(child => {
      // find if child is active element
      return child.closest('a') === inputIsFocused;
    })

    if (e.key === 'ArrowUp') {
      // go to the previous item in the array, when this is < 1 go back to the inputElement
      if (activeResultIndex > 1) {
        resultItemsToArray[activeResultIndex - 1].closest('a')?.focus()
      } else {
        document.getElementById('search_bar')?.focus()
      }
     } else if (e.key === 'ArrowDown') {
      // prevent the <body> from scrolling with keydown event.
      e.preventDefault()

      resultsRef?.current?.querySelector('a')?.focus()

      // go to the next item in the array, when this is 5 go back to the inputElement
      if (activeResultIndex < 5) {
        resultItemsToArray[activeResultIndex + 1].closest('a')?.focus()
      } else {
        document.getElementById('search_bar')?.focus()
      }
    } else if (e.key === 'Escape') {
      document.getElementById('search_bar')?.blur()
    }
  }, [])

  const [debounceUpdateQuery, cancelDebouncedUpdateQuery] = useMemo(() => debounce(updateQuery, 200), [updateQuery]);


  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyboardNavigation);
    return () => {
      window.removeEventListener("keydown", handleKeyboardNavigation)
    };
  },[handleKeyboardNavigation]);

  const updateQueryHandler = React.useCallback((event: Event) => {
    debounceUpdateQuery((event.currentTarget as HTMLInputElement).value)
  }, [debounceUpdateQuery]);

  const clearState = React.useCallback(() => {
    cancelDebouncedUpdateQuery();

    updateQuerySearchParams("");
    autocomplete.setQuery("");
    autocomplete.refresh();
  }, [autocomplete, cancelDebouncedUpdateQuery, updateQuerySearchParams]);

  const emptyQueryCollection = useMemo(() => {
    return autocompleteState.collections.find(c => c.source.sourceId === autocompleteConfig.emptyQueryCollectionId);
  }, [autocompleteState, autocompleteConfig]);

  useEffect(() => {
    if (emptyQueryCollection && emptyQueryCollection.items.length == 0) {
      popoverContainer.classList.add("is-hidden-above-desktop");
    } else {
      popoverContainer.classList.remove("is-hidden-above-desktop");
    }
  }, [emptyQueryCollection, popoverContainer.classList]);

  useEffect(() => {
    autocomplete.refresh();

    inputElements.forEach((input) => input.addEventListener("input", updateQueryHandler));
    clearInputElements.forEach((button) => button.addEventListener("click", clearState));

    return () => {
      inputElements.forEach((input) => input.removeEventListener("input", updateQueryHandler));
      clearInputElements.forEach((button) => button.removeEventListener("click", clearState));
    };
  }, [inputElements, updateQuery, clearInputElements, clearState, initialQuery, autocomplete, updateQueryHandler]);

  return (
    <AutocompleteRenderer
      autocompleteState={autocompleteState}
      autocompleteConfig={autocompleteConfig}
    />
  );
}
