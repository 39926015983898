import { Stripe, StripeElements, PaymentMethod as StripePaymentMethod } from "@stripe/stripe-js";
import { PaymentMethod, PaymentMethodAfterpay, PaymentMethodCard, WalletType } from "./model";

const createPaymentMethod = async ({
  stripe,
  elements,
  billingName,
  billingEmail,
  saveCard,
}: {
  stripe: Stripe;
  elements: StripeElements;
  billingName: string;
  billingEmail: string;
  saveCard: boolean;
}) : Promise<PaymentMethod> => {
  const submitResult = await elements.submit();
  if (submitResult.error) {
    throw new Error(submitResult.error.message);
  }

  const params = {
    billing_details: {
      name: billingName,
      email: billingEmail,
    },
  };

  const paymentMethodResult = await stripe.createPaymentMethod({ elements, params });
  if (paymentMethodResult.error) {
    throw new Error(paymentMethodResult.error.message);
  }

  switch (paymentMethodResult.paymentMethod.type) {
    case "card":
      return buildPaymentMethodCard(paymentMethodResult.paymentMethod, saveCard);

    case "afterpay_clearpay":
      return buildPaymentMethodAfterpay(paymentMethodResult.paymentMethod);

    default:
      throw new Error(`Unknown paymentMethod.type: ${paymentMethodResult.paymentMethod.type}`);
  }
}

const buildPaymentMethodCard = (paymentMethod: StripePaymentMethod, saveCard: boolean) : PaymentMethod => {
  if (!paymentMethod.card) {
    throw new Error("Got a card payment method without a card response");
  }

  const walletType = ((card: StripePaymentMethod.Card) : WalletType => {
    switch (card.wallet?.type) {
      case "google_pay":
        return WalletType.GooglePay;

      case "apple_pay":
        return WalletType.ApplePay;

      default:
        return WalletType.None;
    }
  })(paymentMethod.card);

  const description = ((last4: string) : string => {
    switch (walletType) {
      case WalletType.GooglePay:
        return `•••• ${last4}`;

      case WalletType.ApplePay:
        return `•••• ${last4}`;

      case WalletType.None:
        return `•••• ${last4}`;
    }
  })(paymentMethod.card.last4)

  return (
    new PaymentMethodCard({
      id: paymentMethod.id,
      brand: paymentMethod.card.brand,
      walletType: walletType,
      expiryYear: paymentMethod.card.exp_year,
      expiryMonth: paymentMethod.card.exp_month,
      description: description,
      saveCard: saveCard,
      isDefault: false, 
    }
    )
  );
}

const buildPaymentMethodAfterpay = (paymentMethod: StripePaymentMethod) => {
  if (!paymentMethod.billing_details.name || !paymentMethod.billing_details.email)
  throw new Error("Got a Afterpay payment method without billing details object");

  return (
    new PaymentMethodAfterpay(
      paymentMethod.id,
      paymentMethod.billing_details.name,
      paymentMethod.billing_details.email,
      false
    )
  );

}

export { createPaymentMethod };
