import React from "react";

const DividerHeader = () => {
  return (
    <div data-testid="divider-header" className="divider has-content">
      <div className="label">Payment details</div>
    </div>
  );
}

export { DividerHeader };
