import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/browser";

declare global {
  interface Window {
    RequestReview: MessagePort;
  }
}

export default class ReviewsController extends Controller {
  static targets = ["appReviewModal"];

  declare appReviewModalTarget : HTMLElement;

  async requestReview() {
    if (window.RequestReview !== undefined) {
      window.RequestReview.postMessage('RequestReview');
      await this.logAppReviewResponse("Presented");
    }
  }

  async appReviewPostponed() {
    await this.logAppReviewResponse("Postponed");
  }

  async appReviewRejected() {
    await this.logAppReviewResponse("Rejected");
  }

  private async logAppReviewResponse(response: string) {
    const bodyContent = {
      "app_review_response": response
    }

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;

    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }

    const csrfToken = metaElement.content;
    const url = "/log_app_review_response";

    const result = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Accepts": "application/json",
        "X-CSRF-Token": csrfToken
      },
      body: JSON.stringify(bodyContent)
    });

    if (result.status != 200) {
      const errorMessage = await result.text();
      Sentry.captureMessage(errorMessage);
    }
  }
}
