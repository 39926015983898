import { MenuItem } from "./menu_item";
import { OrderItem, RawOrder, MetricsEventData } from "./order";

class OrderItemApiClient {
  readonly authenticityToken : string;
  readonly addItemUrl : string;
  readonly removeItemUrl : string;

  constructor(authToken: string, addItemUrl: string, removeItemUrl: string) {
    this.authenticityToken = authToken;
    this.addItemUrl = addItemUrl;
    this.removeItemUrl = removeItemUrl;
  }

  async addItem(menuItem: MenuItem) : Promise<RawOrder & MetricsEventData> {
    const menuItemJson = menuItem.serialiseToJson();

    const response = await fetch(this.addItemUrl, {
      "method": "POST",
      "credentials": "include",
      "headers": {
        "Content-Type": "application/json",
        "Accepts": "application/json",
        "X-CSRF-Token": this.authenticityToken
      },
      "body": JSON.stringify(menuItemJson)
    });
    const responseJson = await response.json();
    if (response.status == 200){
      return Promise.resolve(responseJson);
    } else {
      return Promise.reject(new Error(responseJson.error));
    }
  }

  async removeItem(orderItem: OrderItem) : Promise<RawOrder & MetricsEventData> {
    const bodyContent = { "orderItemUUID": orderItem.id };

    const response = await fetch(this.removeItemUrl, {
      "method": "DELETE",
      "credentials": "include",
      "headers": {
        "Content-Type": "application/json",
        "Accepts": "application/json",
        "X-CSRF-Token": this.authenticityToken
      },
      "body": JSON.stringify(bodyContent)
    });
    return response.json();
  }
}

export { OrderItemApiClient };
