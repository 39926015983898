import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "submitButton", "submitButtonText", "submitButtonAnimation", "contactForm"];

  declare modalTarget : HTMLElement;
  declare submitButtonTarget : HTMLButtonElement;
  declare submitButtonTextTarget : HTMLElement;
  declare submitButtonAnimationTarget : HTMLElement;
  declare contactFormTarget : HTMLFormElement;

  openModal() {
    this.modalTarget.classList.add("is-active");
  }

  closeModal() {
    this.modalTarget.classList.remove("is-active");
  }

  enableSubmit() {
    this.submitButtonTarget.disabled = false;
  }

  performSubmit() {
    if (this.contactFormTarget.checkValidity()) {
      this.submitButtonTarget.classList.add("is-loading");
      this.submitButtonTarget.disabled = true;
      this.submitButtonTextTarget.innerHTML = "Sending...";
      this.submitButtonAnimationTarget.classList.toggle("is-completely-hidden");
      this.contactFormTarget.submit();
    } else {
      // The default handler for `invalid` events does not behave well with the sticky header, and `scrollIntoView`
      // behaves differently across user-agents.  It turns out, however, that we see reasonable and consistent behaviour
      // if that function is implicitly scrolling _down_ the page.
      scrollTo({ top: 0 });
    }
  }
}
