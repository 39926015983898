import { Controller } from "@hotwired/stimulus";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { createRoot } from "react-dom/client";
import { CustomerSubscriptionContainer } from "src/checkout/customer_subscriptions";
import { parseSubscriptions } from "src/storefront/types/subscription";
import { Temporal } from "@js-temporal/polyfill";

export default class extends Controller<HTMLFormElement> {
  static targets = ["customerSubscriptions"];
  declare customerSubscriptionsTarget : HTMLDivElement;

  static values = {
    stripePublicKey: String,
    path: String,
    billingName: String,
    billingEmail: String,
    price: Number,
    isEligibleForTrial: Boolean,
    trialPeriodDays: Number,
    nextPaymentDate: String,
    hasReceivedInvoice: Boolean,
  };

  declare stripePublicKeyValue?: string;
  declare billingNameValue: string;
  declare billingEmailValue: string;
  declare priceValue: number;
  declare isEligibleForTrialValue: boolean;
  declare trialPeriodDaysValue: number;
  declare nextPaymentDateValue: string;
  declare hasReceivedInvoiceValue: boolean;

  async connect() {
    if (!this.stripePublicKeyValue) {
      throw "Missing checkout configuration";
    }

    const stripe = await loadStripe(this.stripePublicKeyValue);
    const root = createRoot(this.customerSubscriptionsTarget);

    const rawSubscriptionInfo = this.data.get("subscription-info");
    let activeSubscription;

    if (rawSubscriptionInfo) {
      activeSubscription = parseSubscriptions(JSON.parse(rawSubscriptionInfo));
    }

    root.render(React.createElement(CustomerSubscriptionContainer, {
      stripe: stripe,
      formElement: this.element,
      billingName: this.billingNameValue,
      billingEmail: this.billingEmailValue,
      price: this.priceValue,
      activeSubscription: activeSubscription,
      isEligibleForTrial: this.isEligibleForTrialValue,
      trialPeriodDays: this.trialPeriodDaysValue,
      nextPaymentDate: Temporal.PlainDate.from(this.nextPaymentDateValue),
      hasReceivedInvoice: this.hasReceivedInvoiceValue
    }));
  }

  async submit(event: Event): Promise<void> {
    event.preventDefault();
  }
}
