import { Controller } from "@hotwired/stimulus";

declare global {
  interface Window { ChangeTarget: MessagePort}
}

export default class extends Controller {
  static targets = [
    "overlay", "changeDeliveryTimeForm", "changeDeliveryTypeForm", "deliveryOverlay", "searchBarOverlay",
    "searchBarInput", "applyButtonText", "applyButtonAnimation",
    "unavailableOverlay"
  ];

  declare overlayTarget : HTMLElement;
  declare deliveryOverlayTarget: HTMLElement;
  declare searchBarOverlayTarget: HTMLElement;
  declare searchBarInputTarget: HTMLInputElement;
  declare changeDeliveryTimeFormTarget : HTMLFormElement;
  declare changeDeliveryTypeFormTarget : HTMLFormElement;
  declare applyButtonTarget : HTMLButtonElement;
  declare applyButtonTextTarget : HTMLSpanElement;
  declare applyButtonAnimationTarget : HTMLSpanElement;
  declare unavailableOverlayTarget : HTMLElement;

  declare hasOverlayTarget: boolean;

  changeDeliveryTime() {
    this.changeDeliveryTimeFormTarget.submit();
  }

  changeDeliveryType() {
    this.changeDeliveryTypeFormTarget.submit();
  }

  openNav(event: Event): void {
    event.stopPropagation();
    this.overlayTarget.classList.add("is-active");
  }

  closeNav() {
    this.overlayTarget.classList.remove("is-active");
  }

  supressClose(event: Event) {
    event.stopPropagation();
  }

  openDeliveryDetails() {
    this.deliveryOverlayTarget.classList.add("is-active");
  }

  openUnavailableModal(event: Event) {
    event.stopPropagation();
    this.unavailableOverlayTarget.classList.add("is-active");
  }

  closeUnavailableModal() {
    this.unavailableOverlayTarget.classList.remove("is-active");
  }

  closeDeliveryDetails() {
    this.deliveryOverlayTarget.classList.remove("is-active");
  }

  openSearchBar() {
    this.searchBarOverlayTarget.classList.add("is-active");
  }

  handleFocusState(event: TransitionEvent) {
    if (event.target != this.searchBarOverlayTarget) return;

    if (this.searchBarOverlayTarget.classList.contains("is-active")) {
      this.searchBarInputTarget.focus();
    } else {
      this.searchBarInputTarget.blur();
    }
  }

  closeSearchBar() {
    this.searchBarOverlayTarget.classList.remove("is-active");
  }

  closeAll(event: KeyboardEvent) {
    if (event.key != "Escape") {
      return;
    }

    if (this.hasOverlayTarget) {
      this.closeNav();
    }
  }

  // Used by the customer app to help change environments
  changeTarget() {
    if (window.ChangeTarget !== undefined) {
      window.ChangeTarget.postMessage('ChangeTarget');
    }
  }
}
