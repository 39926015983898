import { Controller } from "@hotwired/stimulus";
import { MenuItemData } from "../../src/menu_item";

export default class MenuItemController extends Controller {
  static targets = ["data", "isClosed"];
  declare dataTarget : HTMLElement;
  declare isClosedTarget : HTMLInputElement

  info : MenuItemData | null = null;
  hasAddress : boolean = false;

  connect() {
    this.info = JSON.parse(this.dataTarget.innerText);

    const hasAddressElement = document.querySelector("[data-has-address]");
    if (hasAddressElement) {
      this.hasAddress = hasAddressElement.getAttribute("data-has-address") == "true";
    }
  }

  async open(event : Event) {
    if (this.available() == true) {
      const ev = new CustomEvent("openModal", { detail: this.info, bubbles: true });
      this.element.dispatchEvent(ev);
    } else if (this.closed() == true) {
      event.stopPropagation();
      const ev = new Event("openUnavailableModal", { bubbles: true });
      this.element.dispatchEvent(ev);
    } else {
      event.stopPropagation();
      const ev = new Event("openDeliveryDetails", { bubbles: true });
      this.element.dispatchEvent(ev);
    }
  }

  available() {
    if (this.element.classList.contains("is-unavailable") === true) return false;

    return true;
  }

  closed(){
    if (this.isClosedTarget.value == "true") return true;

    return false;
  }
}
