import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cartButtonTotal"];

  declare cartButtonTotalTarget: HTMLElement;

  showCart() {
    const event = new Event("openCart", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  populateCartButtonTotal(event: CustomEvent) {
    this.cartButtonTotalTarget.innerText = event.detail;
  }
}
