import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "submitButton"]
  declare modalTarget : HTMLElement
  declare submitButtonTarget : HTMLButtonElement

  displayModal(event: Event) : void {
    event.preventDefault();
    this.modalTarget.classList.add("is-active")
  }

  confirm() : void {
    this.submitButtonTarget.form?.submit()
  }

  cancel() : void {
    this.modalTarget.classList.remove("is-active")
  }
}
