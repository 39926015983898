import { Controller } from "@hotwired/stimulus";

declare global {
  interface Window { RequestNotifications: MessagePort; updateNotificationToken: ((x: string, y: string) => Promise<void>) }
}

// For customer mobile app notification registration
export default class extends Controller {
  requestNotification(): void {
    window.updateNotificationToken = this.updateNotificationToken.bind(this);

    if (window.RequestNotifications !== undefined) {
      window.RequestNotifications.postMessage("requestNotification");
    } else {
      console.log("No notifications handler found!");
    }
  }

  // Called by the customer app webview using runJavascript
  async updateNotificationToken(token: string, status: string) : Promise<void> {
    window.dataLayer.push({ event: `notification_permission_${status}` });

    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    const csrfToken = metaElement?.content || "";

    await fetch(`/new_look/orders/${this.data.get("order-token")}/update_push_token`, {
      "method": "POST",
      "credentials": "same-origin",
      "headers": {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
      "body": JSON.stringify({
        token: token,
        status: status ?? 'unknown',
      }),
    });
  }
}
