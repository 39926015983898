import { Controller } from "@hotwired/stimulus";

export default class GaController extends Controller {
  // Why does this look for a data-ga property and split on that 
  // instead of having three attributes? This helps us keep the DOM
  // a little more readable.
  event(ev: { target: HTMLElement }) : void {
    const gaAttr = ev.target.getAttribute("data-ga");

    if (gaAttr === null) {
      console.warn("Missing data-ga element", ev.target);
      return;
    }

    const [category, action, value] = gaAttr.split(",");

    if (category === null || action === null) {
      console.warn("Missing data-ga components, format is `category,action,value`");
      return;
    }

    if (typeof(ga) != "undefined") {
      ga('send', 'event', category, action, value);
    }
  }
}
