import React from "react";
import { PaymentMethod } from "./model";
import { NewPaymentMethodForm } from "./new_payment_method_form";
import { SavedPaymentMethodSelector } from "./saved_payment_methods_selector";

enum PaymentEntryMode {
  NewPaymentMethod,
  SavedPaymentMethod,
}

const PaymentDetails = ({
  savedPaymentMethods,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  setPaymentDetailsComplete,
  billingName,
  billingEmail,
  allowSaveCard,
  enabledPaymentMethods,
  onSaveCardChange,
  confirmButtonCopy,
} : {
  savedPaymentMethods: PaymentMethod[];
  selectedPaymentMethod?: PaymentMethod;
  setSelectedPaymentMethod: (newPaymentMethod: PaymentMethod) => Promise<string | undefined>;
  setPaymentDetailsComplete: (isComplete: boolean) => void;
  billingName: string;
  billingEmail: string;
  allowSaveCard: boolean;
  enabledPaymentMethods: string[];
  onSaveCardChange: (newSaveCard: boolean) => void;
  confirmButtonCopy?: string;
}) => {
  const entryMode = () => {
    if (savedPaymentMethods.length <= 0)
      return PaymentEntryMode.NewPaymentMethod;

    return PaymentEntryMode.SavedPaymentMethod;
  }

  switch (entryMode()) {
    case PaymentEntryMode.NewPaymentMethod:
      return (
        <NewPaymentMethodForm
          suppressHeader={true}
          setPaymentDetailsComplete={setPaymentDetailsComplete}
          billingName={billingName}
          billingEmail={billingEmail}
          allowSaveCard={allowSaveCard}
          onSaveCardChange={onSaveCardChange}
        />
      );

    case PaymentEntryMode.SavedPaymentMethod:
      if (selectedPaymentMethod === undefined)
        throw "Somehow has no selected payment method?!";

      return (
        <SavedPaymentMethodSelector
          selectedPaymentMethod={selectedPaymentMethod}
          savedPaymentMethods={savedPaymentMethods}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          billingName={billingName}
          billingEmail={billingEmail}
          allowSaveCard={allowSaveCard}
          enabledPaymentMethods={enabledPaymentMethods}
          confirmButtonCopy={confirmButtonCopy}
        />
      );
  }
}


export { PaymentDetails };
