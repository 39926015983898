import { Controller } from "@hotwired/stimulus";
import { loadStripe, Stripe, StripeElements, StripeCardElement } from "@stripe/stripe-js";

export default class StripeCardsController extends Controller {
  static targets = ["form", "formErrors", "stripeElement", "addCardButton"];

  declare formTarget : HTMLFormElement;
  declare formErrorsTarget : HTMLElement;
  declare stripeElementTarget : HTMLElement;
  declare hasStripeElementTarget : boolean;
  declare addCardButtonTarget : HTMLInputElement;

  private stripe : Stripe | null = null;
  private elements : StripeElements | null = null;
  private card : StripeCardElement | null = null;

  /* Load in the stripe element and hide if the user has saved cards */
  async connect(): Promise<void> {
    if (this.hasStripeElementTarget === false){
      return;
    }

    const apiKey : string = this.data.get("key") || "";
    this.stripe = await loadStripe(apiKey);

    if (this.stripe === null) {
      return;
    }

    const style = {
      base: {
        color: '#000000',
        lineHeight: '32px',
        fontFamily: '"Geograph", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '1.142857143rem',
        '::placeholder': {
          color: '#bbbbbb'
        }
      },
      invalid: {
        color: '#FF3312',
        iconColor: '#FF3312'
      }
    };

    this.elements = this.stripe.elements({ mode: 'setup', currency: 'nzd' });
    this.card = this.elements.create('card', { style: style, hidePostalCode: true });
    this.card.mount(this.stripeElementTarget);
    this.clearErrors()
  }

  async addCard(event: Event): Promise<void> {
    event.preventDefault();
    this.clearErrors();

    if (this.stripe === null || this.elements == null || this.card === null) {
      return;
    }

    const submitResult = await this.elements.submit();

    if (submitResult.error) {
      this.formErrorsTarget.classList.remove("is-completely-hidden");
      this.formErrorsTarget.innerText = submitResult.error.message ?? "";
      return;
    }

    this.addCardButtonTarget.setAttribute("disabled", "true");

    const result = await this.stripe.createPaymentMethod({
      elements: this.elements,
    });

    if (result.error !== undefined) {
      this.addCardButtonTarget.removeAttribute("disabled");
      this.formErrorsTarget.classList.remove("is-completely-hidden");
      this.formErrorsTarget.innerText = result.error.message ?? "";

    } else {
      this.stripeTokenHandler(result.paymentMethod.id);
    }
  }

  stripeTokenHandler(id: string): void {
    const hiddenInput = document.createElement('input');

    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'stripeToken');
    hiddenInput.setAttribute('value', id);

    this.formTarget.appendChild(hiddenInput);

    this.formTarget.submit();
  }

  clearErrors(): void {
    this.formErrorsTarget.innerText = ""
    this.formErrorsTarget.classList.add("is-completely-hidden");
  }
}
