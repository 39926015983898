import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "phoneInput", "confirmButton", "formCard", "successCard", "boldNumber",
    "inputFieldWrapper", "inputFieldError"
  ];

  declare phoneInputTarget : HTMLInputElement;
  declare formCardTarget : HTMLSpanElement;
  declare successCardTarget : HTMLSpanElement;
  declare boldNumberTarget : HTMLElement;
  declare confirmButtonTarget : HTMLButtonElement;
  declare inputFieldWrapperTarget : HTMLDivElement;
  declare inputFieldErrorTarget : HTMLDivElement;

  connect() : void {
    this.validate();
  }

  validate() : boolean {
    this.clearError();

    if (this.phoneInputTarget.value.length < 3 || /[a-zA-Z]/.test(this.phoneInputTarget.value)) {
      this.confirmButtonTarget.setAttribute("disabled", "disabled");
      return false;
    } else {
      this.confirmButtonTarget.removeAttribute("disabled");
      return true;
    }
  }

  setError(message : string) : void {
    this.inputFieldWrapperTarget.classList.add("has-error");
    this.inputFieldErrorTarget.innerText = message;
  }

  clearError() : void {
    this.inputFieldWrapperTarget.classList.remove("has-error");
    this.inputFieldErrorTarget.innerText = "";
  }

  getCsrfToken() : string {
    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;
    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }
    return metaElement.content;
  }

  displaySuccessCard() : void {
    this.boldNumberTarget.innerText = this.phoneInputTarget.value;
    this.formCardTarget.classList.add("is-completely-hidden");
    this.successCardTarget.classList.remove("is-completely-hidden");
  }
}
