export class Invoice {
  readonly created: string;
  readonly amountPaid: number;
  readonly invoiceStripeId: string;
  readonly id: number;
  readonly currentPeriodStart: string;
  readonly currentPeriodEnd: string;

  constructor({
    created,
    amountPaid,
    invoiceStripeId,
    id,
    currentPeriodStart,
    currentPeriodEnd
  } : {
    created: string;
    amountPaid: number;
    invoiceStripeId: string;
    id: number;
    currentPeriodStart: string;
    currentPeriodEnd: string;
  }) {
    this.created = created;
    this.amountPaid = amountPaid;
    this.invoiceStripeId = invoiceStripeId;
    this.id = id;
    this.currentPeriodStart = currentPeriodStart;
    this.currentPeriodEnd = currentPeriodEnd;
  }
}
