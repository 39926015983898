export enum OrderStatus {
  Cancelled = "cancelled",
  Confirming = "confirming",
  Preparing = "preparing",

  // delivery only:
  OutForDelivery = "out_for_delivery",
  Delivered = "delivered",

  // customer pickup only:
  ReadyForPickup = "ready_for_pickup",
  PickedUp = "picked_up"
}
