import { Controller } from "@hotwired/stimulus";

interface AnswersArray {
  [index: number]: number;
}
export default class extends Controller {
  static targets = ["question", "detailsForm", "previousQuestion", "nextQuestion", "answer", "title", "radioButtonError", "failedScreeningForm", "answersBlob"];

  declare detailsFormTarget : HTMLFormElement;
  declare failedScreeningFormTarget: HTMLFormElement;
  declare questionTargets : HTMLDivElement [];
  declare answerTargets : HTMLInputElement [];
  declare titleTarget : HTMLElement;
  declare previousQuestionTarget: HTMLButtonElement;
  declare nextQuestionTarget: HTMLButtonElement;
  declare radioButtonErrorTarget: HTMLDivElement;
  declare answersBlobTarget: HTMLInputElement;

  questionIndex = 0;

  answers: AnswersArray = {};

  connect() : void {
    this.previousQuestionTarget.setAttribute("disabled", "true");
    this.displayQuestion();
  }

 nextQuestion() : void {
    if (this.questionIndex == this.questionTargets.length){
      this.detailsFormTarget.submit();
    } else if (this.checkValidAnswer()){
      if (this.questionIndex < this.questionTargets.length - 1 ){
        this.clearContent();
        this.questionIndex ++;
        this.displayQuestion()
      } else if (this.questionIndex == this.questionTargets.length - 1 ){
        if (this.checkAllAnswersValid()){
          this.clearContent();
          this.questionIndex ++;
          this.showSubmitForm()
        } else {
          this.failedScreeningFormTarget.submit();
        }
      }
    }
  }

  previousQuestion(): void {
    this.clearContent();
    if (this.questionIndex > 0 ){
      this.questionIndex --;
      if (this.questionIndex == 0){
        this.previousQuestionTarget.setAttribute("disabled", "true");
      }
    }
    this.displayQuestion()
  }

  showRadioButtonError(): void {
    this.radioButtonErrorTarget.classList.remove("is-completely-hidden");
  }

  private displayQuestion() : void {
    this.questionTargets.find(question => parseInt(question.dataset["questionIndex"] || "0") == this.questionIndex)?.classList.remove("is-completely-hidden");
  }

  private showSubmitForm() : void {
    this.titleTarget.innerText = "Sweet — let's grab a few details"
    this.detailsFormTarget.classList.remove("is-completely-hidden")
    this.nextQuestionTarget.dataset["action"] = "click->form-validator#validateFields click->driver-advertisements#nextQuestion"
  }

  private checkAllAnswersValid() : boolean {
    if (this.answerTargets.some(answer => answer.checked && answer.dataset["correct"] == "")) {
      return false;
    } else {
      return true
    }
  }

  private checkValidAnswer() : boolean {
    const selectedAnswer = this.answerTargets.find((answer : HTMLInputElement) => {
      const selectedQuestionIndex = parseInt(answer.dataset["questionIndex"] || "0")
      return selectedQuestionIndex == this.questionIndex && answer.checked
    });

    if (selectedAnswer?.dataset["answerIndex"] != undefined){
      this.answers[this.questionIndex] = parseInt(selectedAnswer?.dataset["answerIndex"]);
      this.answersBlobTarget.value = JSON.stringify(this.answers);
    }

    if (selectedAnswer == undefined){
      this.showRadioButtonError();
      return false
    } else {
      return true;
    }
  }

  private clearContent() : void {
    this.radioButtonErrorTarget.classList.add("is-completely-hidden");
    this.questionTargets.forEach((el) => {
      el.classList.add("is-completely-hidden");
    });
    this.detailsFormTarget.classList.add("is-completely-hidden")
    this.titleTarget.innerText = "First, a few questions"
    this.nextQuestionTarget.removeAttribute("disabled");
    this.previousQuestionTarget.removeAttribute("disabled");
    this.nextQuestionTarget.dataset["action"] = "click->driver-advertisements#nextQuestion"
  }
}
