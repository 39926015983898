import React from "react";
import { classNames } from "src/common_util";

const SubmitButton = ({
  buttonCopy,
  isPrimary,
  enabled,
  onSubmit,
  hasBottomSpacing,
  dataTestId,
}: {
  buttonCopy: string;
  isPrimary: boolean;
  enabled: boolean;
  onSubmit: () => Promise<void>;
  hasBottomSpacing: boolean;
  dataTestId?: string;
}) => {
  const classes = classNames({
    "button space-mr-sm": true,
    "is-primary": isPrimary,
    "is-visually-disabled": !enabled,
    "space-mb-sm": hasBottomSpacing,
  });

  return (
    <button
      name="button"
      type="submit"
      className={classes}
      disabled={!enabled}
      data-testid={dataTestId}
      data-cy="place-order-button"
      onClick={onSubmit}
    >
      <span className="icon custom-icon is-completely-hidden">
      </span>

      <span>
        {buttonCopy}
      </span>
    </button>
  );
};

export { SubmitButton };
