import React from 'react'
import { ReactElement } from "react";
import { ReactComponent as GooglePay } from "../../images/payment-marks/google-pay.svg";
import { ReactComponent as ApplePay } from "../../images/payment-marks/apple-pay.svg";

export enum WalletType {
  None,
  GooglePay,
  ApplePay,
}

export const paymentMethodWalletTypeName = (walletType:WalletType): string => {
  switch (walletType) {
    case WalletType.GooglePay:
      return (
        "Google Pay"
      )
    case WalletType.ApplePay:
      return (
        "Apple Pay"
      )
    default:
    return (
      ""
    )
  }
}

export const paymentMethodWalletTypeImage = (walletType:WalletType): ReactElement => {
  switch (walletType) {
    case WalletType.GooglePay:
      return (
        <GooglePay />
      )
    case WalletType.ApplePay:
      return (
        <ApplePay />
      )
    default:
    return (
      <></>
    )
  }
}
