import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "tagline"
  ];

  declare taglineTarget: HTMLElement;

  scrollDown() : void {
    const scrollOffset = this.taglineTarget.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
      top: window.innerHeight - scrollOffset + 1,
      left: 0,
      behavior: 'smooth'
    });
  }
}
