class MenuItem {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly image: string;
  readonly price: number;
  readonly ageRestricted: boolean;
  readonly optionSets: OptionSet[];

  quantity: number = 1;
  mealPreferences: string = "";

  constructor(id: number, name: string, description: string, image: string, price: number, ageRestricted: boolean, optionSets: OptionSet[]) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.image = image;
    this.price = price;
    this.ageRestricted = ageRestricted
    this.optionSets = optionSets;
  }

  itemSubtotal(): number {
    const optionsTotal = this.optionSets.reduce((acc, optSet) => {
      return acc + optSet.options.reduce((acc, opt) => {
        if (opt.selected) {
          return acc + opt.surcharge;
        } else {
          return acc;
        }
      }, 0.00);
    }, 0.00);

    return this.price + optionsTotal;
  }

  subtotal() {
    return this.itemSubtotal() * this.quantity;
  }

  incrementQuantity() {
    this.quantity += 1;
  }

  decrementQuantity() {
    this.quantity -= 1;
    this.quantity = Math.max(this.quantity, 1);
  }

  reset() {
    this.quantity = 1;
    this.optionSets.forEach(optionSet => {
      optionSet.options.forEach(option => {
        option.deselect();
      });
    });
    this.mealPreferences = "";
  }

  serialiseToJson() {
    return {
      menuItemId: this.id,
      quantity: this.quantity,
      mealPreferences: this.mealPreferences,
      optionSets: this.actuallyOptionSets().map(os => os.serialiseToJson()),
      additionSets: this.actuallyAdditionSets().map(os => os.serialiseToJson())
    };
  }

  actuallyOptionSets() : OptionSet[] {
    return this.optionSets.filter(os => os.isSecretlyAdditionSet != true);
  }

  actuallyAdditionSets() : OptionSet[] {
    return this.optionSets.filter(os => os.isSecretlyAdditionSet == true);
  }

  static fromObject(obj: RawMenuItem) {
    const optionSets : OptionSet[] = obj.optionSets.map(os => OptionSet.fromObject(os));
    return new MenuItem(obj.id, obj.name, obj.description, obj.largeImage, obj.price, obj.ageRestricted, optionSets);
  }
}

class OptionSet {
  readonly id: number;
  readonly name: string;
  readonly position: number;
  readonly minimumSelections: number;
  readonly maximumSelections: number;
  readonly options: Option[];
  readonly isSecretlyAdditionSet: boolean = false;

  constructor(id: number, name: string, position: number, min: number, max: number, options: Option[], isSecretlyAdditionSet: boolean) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.minimumSelections = min;
    this.maximumSelections = max;
    this.options = options;
    this.isSecretlyAdditionSet = isSecretlyAdditionSet;
  }

  isRadioSelect(): boolean {
    return this.minimumSelections == 1 && this.maximumSelections == 1;
  }

  select(option: Option) {
    if (this.isRadioSelect()) {
      this.options.forEach(option => option.deselect());
    }

    option.select();
  }

  deselect(option: Option) {
    option.deselect();
  }

  isValid() {
    const numberOfSelections = this.options.filter(option => option.selected).length;
    return (
      this.minimumSelections <= numberOfSelections && numberOfSelections <= this.maximumSelections
    );
  }

  serialiseToJson() {
    return {
      id: this.id,
      selections: this.options.filter(option => option.selected).map(option => {
        return {
          id: option.id
        };
      })
    }
  }

  static fromObject(obj: RawOptionSet): OptionSet {
    const options : Option[] = obj.options.map(o => Option.fromObject(o));

    return new OptionSet(
      obj.id,
      obj.name,
      obj.position,
      obj.minimumSelections,
      obj.maximumSelections,
      options,
      obj.isSecretlyAdditionSet != null
    );
  }
}

class Option {
  readonly id: number;
  readonly name: string;
  readonly position: number;
  readonly surcharge: number;

  selected: boolean = false;

  constructor(id: number, name: string, position: number, surcharge: number) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.surcharge = surcharge;
  }

  select() {
    this.selected = true;
  }

  deselect() {
    this.selected = false;
  }

  surchargeFormatted(): string {
    const formatter = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" });
    return formatter.format(Math.abs(this.surcharge));
  }

  static fromObject(obj: RawOption): Option {
    return new Option(
      obj.id,
      obj.name,
      obj.position,
      obj.surcharge
    );
  }
}

interface RawMenuItem {
  id: number;
  name: string;
  description: string;
  largeImage: string;
  price: number;
  ageRestricted: boolean;
  optionSets: RawOptionSet[];
}

interface RawOptionSet {
  id: number;
  name: string;
  position: number;
  minimumSelections: number;
  maximumSelections: number;
  options: RawOption[];
  isSecretlyAdditionSet: boolean;
}

interface RawOption {
  id: number;
  name: string;
  position: number;
  surcharge: number;
}


interface MenuItemData {
  id: number;
  url: string;
};

export { MenuItem, OptionSet, Option, RawOptionSet, MenuItemData };
