import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "field", "newInstructions", "newInstructionsRadioButton"];

  declare formTarget : HTMLFormElement;
  declare fieldTarget : HTMLInputElement;
  declare newInstructionsTarget : HTMLDivElement;
  declare newInstructionsRadioButtonTarget : HTMLInputElement;

  toggleNewInstructions(){
    if (this.newInstructionsRadioButtonTarget.checked){
      this.newInstructionsTarget.classList.remove("is-completely-hidden");
    } else {
      this.newInstructionsTarget.classList.add("is-completely-hidden");
    }
  }

  deleteInstruction(event: Event) {
    event.preventDefault();

    if (event.currentTarget === null) {
      return;
    }

    const element = event.currentTarget as HTMLElement;
    const instructionId = element.getAttribute("data-id");

    if (instructionId === undefined || instructionId === null) {
      return;
    }

    this.fieldTarget.value = instructionId.toString();
    this.formTarget.submit();
  }
}
