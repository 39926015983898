import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["changeDeliveryTimeForm", "dealTermsAndConditions", "deliveryTimeAndButton"];

  declare changeDeliveryTimeFormTarget : HTMLElement
  declare dealTermsAndConditionsTarget : HTMLElement
  declare deliveryTimeAndButtonTarget : HTMLElement

  displayDeliveryTimeForm() {
    this.changeDeliveryTimeFormTarget.classList.remove("is-completely-hidden")
    this.deliveryTimeAndButtonTarget.classList.add("is-completely-hidden")
  }

  toggleDealTermsAndConditions(event: Event) {
    event.preventDefault();
    this.dealTermsAndConditionsTarget.classList.toggle("is-completely-hidden");
  }
}
