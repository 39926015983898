import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "overlay"
  ];

  declare overlayTargets: HTMLElement[];

  escapeOverlays(event: KeyboardEvent) {
    if (event.key != "Escape") {
      return;
    }

    if (this.overlayTargets) {
      this.overlayTargets.forEach(overlay => {
        if (overlay.classList.contains("is-active")) {
          overlay.classList.remove("is-active");
        }
      });
    }
  }

  closeOverlays(event: UIEvent) {
    if (event.detail && this.overlayTargets) {
      this.overlayTargets.forEach(overlay => {
        if (overlay.contains(event.target as HTMLElement)) return;

        if (overlay.classList.contains("is-active")) {
          overlay.classList.remove("is-active");
        }
      });
    }
  }
}
