import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "content", "controlNext", "controlPrevious"
  ];

  declare contentTarget: HTMLElement;
  declare controlNextTarget: HTMLButtonElement;
  declare controlPreviousTarget: HTMLButtonElement;

  nextItem() : void {
    const scrollSize = this.contentTarget.offsetWidth;

    this.contentTarget.scrollBy(scrollSize, 0);
  }

  previousItem() : void {
    const scrollSize = this.contentTarget.offsetWidth;

    this.contentTarget.scrollBy(-scrollSize, 0);
  }
}
