import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "switchInput"
  ];

  declare switchInputTarget: HTMLInputElement;

  toggleFirst() {
    this.switchInputTarget.checked = false;
  }

  toggleLast() {
    this.switchInputTarget.checked = true;
  }
}
