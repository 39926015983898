import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleableCheckbox", "resetFilter"];

  declare toggleableCheckboxTargets : HTMLInputElement[];
  declare resetFilterTarget: HTMLDivElement;

  connect(): void {
    // Temporary work-around for customers on an older version of the app
    // to fix the infinite loading screen when the hardware back button
    // is used after a filter is selected
    if (window.LoadNotification !== undefined) {
      window.LoadNotification.postMessage('PageLoaded');
    }

    this.hideLoadingBar();

    this.toggleableCheckboxTargets.forEach(this.deselectCheckbox);
    this.resetFilterTarget.classList.add("is-completely-hidden");

    const params = (new URL(window.location.toString())).searchParams;
    const selectedVertical = params.get("vertical");

    this.toggleableCheckboxTargets.forEach((checkbox) => {
      checkbox.addEventListener('click', this.selectFilter.bind(this));

      if (checkbox.value == selectedVertical) {
        this.resetFilterTarget.classList.remove("is-completely-hidden");
        this.selectCheckbox(checkbox);
      }
    })
  }

  selectFilter(event: Event): void {
    this.showLoadingBar();

    this.toggleableCheckboxTargets.forEach(toggleCheckbox => {
      if (event.currentTarget == toggleCheckbox) { // Checkbox that was selected
        this.toggleFilter(toggleCheckbox);
      } else {
        this.deselectCheckbox(toggleCheckbox);
      }
    })
  }

  resetFilters(): void {
    this.showLoadingBar();

    this.toggleableCheckboxTargets.forEach(this.deselectCheckbox);
    this.resetFilterTarget.classList.add("is-completely-hidden");

    const form = document.getElementById(`cat-filter-form-reset`) as HTMLFormElement
    form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
  }

  private toggleFilter(checkbox: HTMLInputElement) {
    if (checkbox.checked) {
      this.resetFilterTarget.classList.remove("is-completely-hidden");
      const form = document.getElementById(`cat-filter-form-${checkbox.value.toLowerCase()}`) as HTMLFormElement
      form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    } else {
      this.resetFilters();
    }

    checkbox.toggleAttribute('checked');
  }

  private deselectCheckbox(checkbox: HTMLInputElement) {
    checkbox.checked = false;
    checkbox.removeAttribute('checked');
  }

  private selectCheckbox(checkbox: HTMLInputElement) {
    checkbox.checked = true;
    checkbox.setAttribute('checked', 'checked');
  }

  private showLoadingBar(): void {
    const loadingBar = document.querySelector("#store-list-frame > .progress-bar");
    if (loadingBar) {
      loadingBar.classList.remove("is-completely-hidden");
    }
  }

  private hideLoadingBar(): void {
    const loadingBar = document.querySelector("#store-list-frame > .progress-bar");
    if (loadingBar) {
      loadingBar.classList.add("is-completely-hidden");
    }
  }
}
