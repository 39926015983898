import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleVisibility(event: Event) {
    event.stopPropagation();
    this.element.classList.toggle("is-active");
  }

  open(event: Event) {
    event.stopPropagation();
    this.element.classList.add("is-active");
  }

  close(event: Event) {
    event.stopPropagation();
    this.element.classList.remove("is-active");
    (event.currentTarget as HTMLElement).blur();
  }
}
